import { render, staticRenderFns } from "./BCXDottedBox.vue?vue&type=template&id=2650674d&scoped=true"
import script from "./BCXDottedBox.vue?vue&type=script&lang=ts&setup=true"
export * from "./BCXDottedBox.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./BCXDottedBox.vue?vue&type=style&index=0&id=2650674d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2650674d",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./BCXPaginationSwitcher.vue?vue&type=template&id=72d57858&scoped=true"
import script from "./BCXPaginationSwitcher.vue?vue&type=script&lang=ts&setup=true"
export * from "./BCXPaginationSwitcher.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./BCXPaginationSwitcher.vue?vue&type=style&index=0&id=72d57858&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72d57858",
  null
  
)

export default component.exports